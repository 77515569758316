<template>
<div v-if="adOpened"
    class="min-w-screen h-screen animated fadeIn faster md:p-0 p-4 fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-ad"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto relative mx-auto my-auto rounded-xl shadow-lg max-w-[80%] max-h-[80%]"
    >
        <Icon.X
          @click="closeAd(ads[0])"
          class="cursor-pointer absolute -top-6 right-1 z-40 text-white"
        />
      <!--content-->
      <div v-html='ads[0].content' class="overflow-y-hidden">
      </div>
    </div>
  </div>
</template>


<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
</script>

<script>
export default {
  props: {
    ads: { type: Object },
  },
  data() {
    return {
      current_addons: [],
      adOpened: false,
    };
  },
  mounted() {
    console.log('Store ad ---------------');
    console.log(this.ads[0]);

    if(this.ads.length > 0) this.showAd(this.ads[0]);
    
  },
  created() {},
  methods: {
    showAd(ad) {
      if(localStorage.getItem('ad_'+ad.id) != 'true'){
        this.adOpened = true;
      }
      console.log('show Ad', ad.name);
    },

    closeAd(ad) {
      this.adOpened = false;
      this.adModal = {};
      localStorage.setItem('ad_'+ad.id, 'true');
    }}
  };
</script>
