<template>
  <div v-if="loading" class="w-full flex items-center justify-center p-40">
    <div
      class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
      role="status"
    >
      <span
        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span
      >
    </div>
  </div>

  <div v-if="!loading" class="p-4 shadow rounded-2xl bg-clip-border break-words bg-white relative">

    <button @click="create" class="p-3 rounded-full bg-black text-white capitalize transition-all hover:bg-slate-600 absolute top-2 right-2 -mt-14">
      <Icon.Plus :size="15" />
    </button>


    <div class="flex flex-wrap -mx-3">
      <div class="flex-none w-full px-4">
        <div class="flex gap-2 font-bold">
          <div class="flex-1">Available</div>
          <div class="flex-1">Nome</div> 
          <div class="flex-1">Valor</div>
          <div class="flex-none w-14">&nbsp;</div>
        </div>
        <div class="flex gap-2 mb-2 cursos-pointer w-full" v-for="ad in ads" :key="ad.id">
          <div class="cursor-pointer flex-1 line-clamp-1" @click="edit(ad)">{{ ad.available }}</div>
          <div class="cursor-pointer flex-1 line-clamp-1" @click="edit(ad)">{{ ad.name }}</div>
          <div class="cursor-pointer flex-1 line-clamp-1" @click="edit(ad)">{{ ad.content }}</div>
          <div class="cursor-pointer flex-none w-14"><Icon.Trash :size="15" @click="remove(ad)"/></div>
        </div>
      </div>
    </div>
  </div>

  
  <div
    v-if="newAdPopup"
    class=" h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-product-addon"
  >

    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-2/4 relative mx-auto my-auto rounded-xl shadow-lg bg-white overflow-y-auto"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="reset"
          class="cursor-pointer absolute top-1 right-1 z-40"
        />
        <!--body-->
        <div class="text-center grid grid-cols-1 gap-4 justify-center p-4">
          <h1 v-if="currentAd.id">Atualizar ánúncio</h1>
          <h1 v-else>Novo anúncio</h1>

          <input v-model="currentAd.available" type="checkbox" placeholder="Available" class="block rounded-3xl py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"/>
          
          <input v-model="currentAd.name" type="text" placeholder="Nome" class="block w-full rounded-3xl py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"/>
          
          <input v-model="currentAd.content" type="text" placeholder="Valor" class="block w-full rounded-3xl py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"/>
        
          <button @click="add" class="px-6 py-3 rounded-3xl bg-black text-white capitalize transition-all hover:bg-slate-600">
            Gravar
          </button>
        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
  import * as Icon from "lucide-vue-next";
</script>

<script>
export default {
  components: {
  },
  props: {
    store_id: { type: String },
  },
  expose: ['load'],
  data() {
    return {
      loading : false,
      newAdPopup : false,
      currentAd : {},
      ads: [],
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    create(){
      this.newAdPopup = true;
      this.currentAd = {
        available : true,
        name : '',
        content : '',
      };
    },
    edit(ad){
      this.newAdPopup = true;
      this.currentAd = {
        id : ad.id,
        available : ad.available,
        name : ad.name,
        content : ad.content,
      };
    },
    remove(ad){
      axios
          .delete("/admin/store/" + this.store_id + "/ad/" + ad.id)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.reset();
          });
    },
    reset(){
      this.loading = false;
      this.newAdPopup = false;
      this.currentAd = {
        available : true,
        name : '',
        content : '',
      };
      this.load();
    },
    add(){
      const self = this;
      this.loading = true;

      if(this.currentAd.id){
        axios
          .put("/admin/store/" + this.store_id + "/ad/" + this.currentAd.id, this.currentAd)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.reset();
          });
      }else{
        axios
          .post("/admin/store/" + this.store_id + "/ad", this.currentAd)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.reset();
          });
        }
    },
    load(){
      console.log('store', this.store_id);
      const self = this;
      this.loading = true;

      axios
      .get("/admin/store/" + this.store_id + "/ad")
      .then((response) => {
        self.ads = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        self.loading = false;
      });
    },
  },
};

</script>
