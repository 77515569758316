<template>    
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4 mb-4">

    <div class="gap-4">
      <div class="w-full px-8 py-4" v-if="orderValues.type == 'delivery' && orderValues.status != 'canceled' && orderValues.status != 'ready'">
        <div class="relative flex items-center justify-between w-full mx-0">
          <div class="absolute left-0 top-2/4 h-0.5 w-[98%] -translate-y-2/4 bg-gray-300"></div>
          <div class="absolute left-0 top-2/4 h-0.5 w-[98%] -translate-y-2/4 bg-gray-900 transition-all duration-500">
          </div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center">1</div>

          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center"
          v-if="orderValues.status == 'ON_DELIVERY' || orderValues.status == 'DELIVERED'">2</div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center"
          v-else>2</div>

          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center"
          v-if="orderValues.status == 'DELIVERED'">3</div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center"
          v-else>3</div>
        </div>
      </div>

      <div v-if="orderValues.type == 'delivery'">
        <div class="">
          <div class="text-lg font-bold">
            Pedido #{{orderValues.code}}
          </div>
          <div class="text-lg font-bold" v-if="orderValues.status == 'NEW' && orderValues.status == 'NEW' || orderValues.status == 'SELECTED' || orderValues.status == 'ACCEPTED' || orderValues.status == 'ON_PREPARING' || orderValues.status == 'READY'">
            Estamos a preparar seu pedido...
          </div>
          <div class="text-lg font-bold" v-if="orderValues.status == 'ON_DELIVERY'">
            A caminho da sua localização...
          </div>
          <div class="text-lg font-bold" v-if="orderValues.status == 'CANCELED'">
            Seu pedido está cancelado
          </div>
        </div>
        <div v-if="orderValues.status != 'CANCELED'">
          <div v-if="orderValues.status != 'DELIVERED'">
            Chegada prevista: {{ format_date(orderValues.estimated_delivery_at, 'HH:mm') }}
          </div>
          <div v-else>
            Pedido entregue: {{ format_date(orderValues.delivered_time, 'DD/MM/YYYY HH:mm') }}
          </div>
        </div>
        <Vue3Lottie animationLink="/images/lottie/order_new.json" :height="200" :width="200" v-if="orderValues.status == 'NEW' || orderValues.status == 'SELECTED' || orderValues.status == 'ACCEPTED'"/>
        <Vue3Lottie animationLink="/images/lottie/order_onpreparing.json" :height="200" :width="200" v-if="orderValues.status == 'ON_PREPARING' || orderValues.status == 'READY'"/>
        <Vue3Lottie animationLink="/images/lottie/order_ondelivery.json" :height="200" :width="200" v-if="orderValues.status == 'ON_DELIVERY'"/>
        <Vue3Lottie animationLink="/images/lottie/order_finished.json" :height="200" :width="200" v-if="orderValues.status == 'DELIVERED'"/>

      </div>
      <div v-else class="mt-8">
          <div class="text-lg font-bold">
            Pedido #{{orderValues.code}}
          </div>
        Estamos a preparar o seu pedido!

        <Vue3Lottie animationLink="/images/lottie/order_onpreparing.json" :height="200" :width="200"/>
      </div>

      <div v-if="orderValues.status == 'ON_DELIVERY'">
        Estafeta responsável pela entrega: {{ orderValues.driver_name }}
          </div>
      
      <div class="" v-if="orderValues.status != 'DELIVERED'">
        Se precisar de ajuda, carregue no botão abaixo para ligar para nós: {{ store.phone }}
      </div>
      <div class="flex flex-col items-center my-4" v-if="orderValues.status != 'DELIVERED'">
        <button class="px-6 py-3 rounded-3xl bg-primary text-white transition-all hover:bg-primary" @click="callToStore">
          Ligar para o restaurante
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 md:mt-20 mt-0">
      <div class="" v-if="orderValues.type == 'delivery'">
        <div class="text-lg font-bold">
          Detalhes da entrega
        </div>    

        <div>          
          <ol class="relative border-s border-gray-500">                  
              <li class="ms-4">
                  <div class="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                  <time class="mb-1 text-sm font-normal leading-none">{{ store.address}}, {{ store.address_number}} {{ store.appartment_number}} - {{ store.zipcode}}</time>
              </li>
              <li class="ms-4">
                  <div class="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                  <time class="mb-1 text-sm font-normal leading-none">{{ orderValues.eater_address}}, {{ orderValues.eater_address_number}} {{ orderValues.eater_appartment_number}} - {{ orderValues.eater_zipcode}}</time>
              </li>
          </ol>
        </div>
        
        <GMapMap class="my-4 w-full h-96 relative" ref="mapX"
          :center="findCenter([{ lat: format_float(orderValues.driver_lat), lng: format_float(orderValues.driver_lng) }, { lat: format_float(orderValues.store.lat), lng: format_float(orderValues.store.lng) }])"
          map-type-id="terrain"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          }"
        >
          <GMapMarker :position="{ lat: format_float(order.store.lat), lng: format_float(order.store.lng) }"/>
          <GMapMarker :position="{ lat: format_float(order.eater_address_lat), lng: format_float(order.eater_address_lng) }"/>
          <GMapMarker v-if="orderValues.status == 'ON_DELIVERY'" :position="{ lat: format_float(orderValues.driver_lat), lng: format_float(orderValues.driver_lng) }" icon='https://images.eatz.pt/icon_map.png?1=1' />
        </GMapMap>
      </div>
      <div class="" v-else>
        <div class="text-lg font-bold">
          Detalhes para levantamento
        </div>        
        
        <div class="">
          <b>{{ store.name}}</b>
        </div>  
        
        <div class="">
          {{ store.address}}, {{ store.address_number}} {{ store.appartment_number}} - {{ store.zipcode}}
        </div>
        
        <GMapMap class="my-4 w-full h-60 relative"
          :center="{ lat: format_float(store.lat), lng: format_float(store.lng) }"
          map-type-id="terrain"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          }"
        >
          <GMapMarker :position="{ lat: format_float(store.lat), lng: format_float(store.lng) }"/>
        </GMapMap>
      </div>
      
      <div class="">
        <div class="text-lg font-bold">
          Resumo do pedido
        </div>
        <div class="">
          <div v-for="item in orderValues.items" v-bind:key="item.id" class="grid grid-cols-2">
            <div class="text-left"><span v-if="item.name != 'Delivery' && item.name != 'Taxa de entrega' && item.name != 'Taxa de Serviço'">{{ item.quantity }} x </span>{{ item.name }}</div>
            <div class="w-full">{{ toCurrency(item.price * item.quantity) }}</div>
          </div>
          <br/>
          <div class="grid grid-cols-2">
            <div class="font-bold">Total</div>
            <div class="text-right">{{ toCurrency(orderValues.price_with_discount) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from 'moment'
import Pusher from 'pusher-js'
</script>

<script>
Pusher.logToConsole = false

export default {
  props: {
    store: { type: Object },
    order: { type: Object },
  },
  data() {
    return {
      selectedTrans :0,
      waypnt: [],
      startLocation: {
          lat: 41.195706084491846,
          lng: -8.683425028938546,
        },
      endLocation: {
          lat: 41.20186775708164,
          lng: -8.539490540972928,
        },
      transfers: [{
        "id": 29,
        "date": "2020-02-12T08:00:00.000Z",
        "pick_up": "Sofia Airport, Terminal 1",
        "drop_off": "Stara Zagora",
        "driver": "СТ",
        "vehicle": 6264,
      },
      {
        "id": 43,
        "date": "2020-02-13T08:30:00.000Z",
        "pick_up": "Sofia Terminal 1",
        "drop_off": "Boutique One Hotel Sofia",
        "driver": "СТ",
        "vehicle": 6264,
      }],
      map : {},
      directionsService : null,
      directionsRenderer : {},
      pusher: null,
      products: [],
      markers: [],
      orderValues: {},
      itemsCount: 0,
      mapPolyline: [
        {lat: this.format_float(this.order.store.lat), lng: this.format_float(this.order.store.lng)},
        {lat: this.format_float(this.order.eater_address_lat), lng: this.format_float(this.order.eater_address_lng)},
      ],
    };
  },
  mounted() {
    const self = this;
    window.LL = this
    console.log('aaaaaa')
    console.log('aaa', this.$refs)
    setTimeout(() => {
      self.map = self.$refs.mapX
      self.loadDirection()
    }, 2000);
    console.log('map', this.map)

    console.log('aaaaaa')


    this.orderValues = this.order;
    this.pusher = new Pusher('5ffe2ab47cbc08c20ae9', { cluster: 'eu' })
    this.channelOrders = this.pusher.subscribe('orders')
    this.channelOrders.bind('orders', data => {
      console.log(data);

      if(data?.order_id == this.orderValues.code){
        this.orderValues.status = data?.status;

        if(data?.status == 'DELIVERED') location.reload();
      }
    })
    
    this.channelPosition = this.pusher.subscribe('position')
    this.channelPosition.bind('position', data => {
      if(data?.order_id == this.orderValues.code){
        this.orderValues.driver_name = data?.driver_name ?? ''
        this.orderValues.driver_lat = data?.driver_lat ?? ''
        this.orderValues.driver_lng = data?.driver_lng ?? ''

        const marker = {
          lat: this.orderValues.driver_lat,
          lng: this.orderValues.driver_lng,
        };
        this.markers.push({ id:1, position: marker });

        this.loadDirection();
      }
      
    })
  },
  created() {
/*
  console.log('this.$gmapApiPromiseLazy()', this.$gmapApiPromiseLazy())
  this.$gmapApiPromiseLazy().then(() => {
    this.directionsService = new google.maps.DirectionsService()
        this.directionsRenderer = new google.maps.DirectionsRenderer()

      console.log('this.map', this.map)
        this.directionsRenderer.setMap(this.map.$mapObject)
      })*/
  },
  methods: {
    newOrderReceived(data) {
      console.log("newOrderReceived", data);
    },

    callToStore(){
      window.open='tel:'+this.store.phone;
    },

    format_date(value, format){
      if (value) {
        return moment(String(value)).format(format)
      }
    },

    format_float(value){
      return parseFloat(value);
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },

    findCenter(markers) {
      const lats = markers.map(m => m.lat);
      const lngs = markers.map(m => m.lng);
      return {
        lat: (Math.min(...lats) + Math.max(...lats)) / 2,
        lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
      };
    },

    loadDirection() {
      this.startLocation = {
          lat: this.format_float(this.order.store.lat),
          lng: this.format_float(this.order.store.lng),
        }
      this.driverLocation = {
          lat: this.format_float(this.order.driver_lat),
          lng: this.format_float(this.order.driver_lng),
        }
        
      this.endLocation = {
          lat: this.format_float(this.order.eater_address_lat),
          lng: this.format_float(this.order.eater_address_lng),
        }

      if(this.directionsService) this.directionsRenderer.setMap(null)


      this.directionsService = new google.maps.DirectionsService()
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        preserveViewport: false,
        suppressMarkers: true
      })
      this.directionsRenderer.setMap(this.$refs.mapX.$mapObject)
      this.calculateAndDisplayRoute(this.directionsService, this.directionsRenderer)

      let bounds = new google.maps.LatLngBounds()
      bounds.extend(this.startLocation)
      bounds.extend(this.driverLocation)
      bounds.extend(this.endLocation)
      this.$refs.mapX.fitBounds(bounds)
      this.$refs.mapX.panToBounds(bounds)
    },

    calculateAndDisplayRoute(directionsService, directionsRenderer) {

      directionsService
        .route({
          origin: this.orderValues.status == 'ON_DELIVERY' ? this.driverLocation : this.startLocation,
          destination: this.endLocation,
          waypoints: this.waypnt,
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          console.log(response)
          directionsRenderer.setDirections(response);
        })
        .catch((e) =>
          window.alert('Directions request failed due to ' + e)
        );
    },
  },
};
</script>